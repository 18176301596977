@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@nbcuniversal-paint/react-ui-kit-css--syfywire/fonts/'; @import "base.default-settings"; @import "syfywire.default-settings"; @import "base.settings"; @import "base.mixins"; @import "syfywire.settings";
.layout {
  position: relative;
}

.loader {
  padding: $element-spacing 0;
}

.wrapper {
  margin-bottom: $element-spacing;
}

.heading {
  line-height: 1em;
  margin-left: $default-spacing;
}

.nav {
  border-bottom: 1px solid $colors-greys-delta;
  margin: $default-spacing 0;
  padding: 0 $default-spacing;
  text-align: center;
}

.link {
  background-color: transparent;
  border-bottom: 5px solid transparent;
  color: $colors-utility-white;
  cursor: pointer;
  display: block;
  padding: $default-spacing 0;
  text-decoration: none;
  transition: all 150ms ease-in-out;
  width: 100%;

  &:hover {
    border-bottom-color: $colors-primary-alpha;
    color: $colors-utility-white;
    text-decoration: none;
  }
}

.list {
  @include grid-default(6, false, 0, 0);
}

.listitem {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.active {
  border-bottom-color: $colors-primary-alpha;
  color: $colors-utility-white;
}

.day {
  position: relative;
}

.displaydate {
  @include vr-plumbing-set($vr-fonts, small);
}

.button {
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.closebutton {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;

  button {
    cursor: pointer;
    margin: 0;
    padding: 0;

    svg {
      fill: invert($default-body-bg-color);
    }
  }
}

.overlay {
  align-items: center;
  background-color: rgba($default-body-bg-color, 0.75);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5;
}

.downloadBtn {
  border: 1px solid invert($default-body-bg-color);
  text-decoration: none;
  padding: $atom-spacing $default-spacing;
}
