@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@nbcuniversal-paint/react-ui-kit-css--syfywire/fonts/'; @import "base.default-settings"; @import "syfywire.default-settings"; @import "base.settings"; @import "base.mixins"; @import "syfywire.settings";
@mixin header-align() {
  align-items: center;
  display: flex;
  justify-content: center;
}

$transition-duration: 150ms;
$transition-easing: ease-in-out;

.logo {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: $header-logo-padding;

  svg {
    display: block;
    height: auto;
    padding: 0;
    width: $header-logo-width;
  }
}

.headerwrapper {
  border-bottom: 1px solid $colors-greys-delta;
  padding: $header-wrapper-padding;
  width: 100%;
}

.menu {
  align-items: center;
  display: flex;
}

.hamburger {
  padding: 0;
}

.headeralign {
  @include header-align();
}

.secondarynavigation {
  grid-area: 1 / 3 / 1 / 3;
  justify-self: end;

  @include breakpoint($desktop) {
    display: block;
  }
}
